import http from "../http-common";

class TutorialDataService {
  getAll() {
    return http.get("/customers");
  }

 

  get(Id) {
    return http.get(`/customers/${Id}`);
  }

  create(data) {
    return http.post("/customers", data);
  }

  update(Id, data) {
    return http.put(`/customers/${Id}`, data);
  }

  delete(Id) {
    return http.delete(`/customers/${Id}`);
  }

  deleteAll() {
    return http.delete(`/customers`);
  }

  findByName(Name) {
    return http.get(`/customers?Name=${Name}`);
  }
}

export default new TutorialDataService();
