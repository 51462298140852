<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <div class="form-group">
        <label for="Name">Name</label>
        <input
          type="text"
          class="form-control"
          id="Name"
          required
          v-model="customer.Name"
          name="Name"
        />
      </div>

      <div class="form-group">
        <label for="MachineType">MachineType</label>
        <input
          class="form-control"
          id="MachineType"
          required
          v-model="customer.MachineType"
          name="MachineType"
        />
      </div>

      <div class="form-group">
        <label for="ContactPerson">Contact Person</label>
        <input
          class="form-control"
          id="ContactPerson"
          required
          v-model="customer.ContactPerson"
          name="ContactPerson"
        />
      </div>

      <button @click="saveCustomer" class="btn btn-success">Submit</button>
    </div>

    <div v-else>
      <h4>You submitted successfully!</h4>
      <button class="btn btn-success" @click="newCustomer">Add</button>
    </div>
  </div>
</template>

<script>
import CustomerDataService from "../../services/CustomerDataService";

export default {
  name: "add-customer",
  data() {
    return {
      customer: {
        Id: null,
        Name: "",
        MachineType: "",
        ContactPerson: "",
      },
      submitted: false,
    };
  },
  methods: {
    saveCustomer() {
      var data = {
        Name: this.customer.Name,
        MachineType: this.customer.MachineType,
        ContactPerson: this.customer.ContactPerson,
      };

      CustomerDataService.create(data)
        .then((response) => {
          this.customer.Id = response.data.Id;
          this.submitted = true;
          this.$router.push({ name: "customers" });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    newCustomer() {
      this.submitted = false;
      this.customer = {};
    },
  },
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
